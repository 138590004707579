import { MenuType } from '@mybestbrands/types';

export const handleAuthApiResponse = (t: any, data: any, onSuccess: () => void, onError: (e: string[]) => void) => {
  if (data.statusCode === 500) {
    onError(['Server error']);
    return;
  }

  if ((data.errorCode || data.error) && data.message) {
    if (data.errorCode) {
      onError([t('ERROR_' + data.errorCode)]);
    } else {
      if (Array.isArray(data.message)) {
        onError(data.message);
      } else {
        onError([data.message]);
      }
    }
  } else {
    onSuccess();
  }
};

export const profileMenu: MenuType[] = [
  {
    name: 'Mein Profil',
    slug: 'profile',
    id: '1',
    url: '/profile/',
    searchTypos: null,
    gender: null,
    children: [
      {
        name: 'Meine Merkliste',
        slug: 'wishlist',
        id: '11',
        url: '/profile/wishlist/',
        searchTypos: null,
        gender: null,
        children: [],
      },
      {
        name: 'Newsletter',
        slug: 'newsletter',
        id: '12',
        url: '/profile/newsletter/',
        searchTypos: null,
        gender: null,
        children: [],
      },
    ],
  },

  {
    name: 'Account',
    slug: 'personal-data',
    id: '2',
    url: '/profile/personal-data/',
    searchTypos: null,
    gender: null,
    children: [
      {
        name: 'Persönliche Daten',
        slug: 'personal-data',
        id: '21',
        url: '/profile/personal-data/',
        searchTypos: null,
        gender: null,
        children: [],
      },
      // {
      //   name: 'Passwort ändern',
      //   slug: 'change-password',
      //   id: '22',
      //   url: '/profile/change-password/',
      //   searchTypos: null,
      //   gender: null,
      //   children: [],
      // },
      {
        name: 'Logout',
        slug: 'logout',
        id: '23',
        url: '/profile/logout/',
        children: [],
        gender: null,
        searchTypos: null,
      },
      {
        name: 'Account löschen',
        slug: 'delete-account',
        id: '24',
        url: '/profile/delete-account/',
        children: [],
        gender: null,
        searchTypos: null,
      },
    ],
  },
];
